import { Button } from '@material-ui/core';
import React, { ReactNode } from 'react';

interface ButtonProps {
  backgroundColor?: string;
  className?: string;
  children?: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  sx?: any;
}

export const CustomButton2 = ({
  backgroundColor = '',
  className = '',
  onClick,
  children,
  disabled = false,
  sx = {},
}: ButtonProps) => (
  <Button
    className={className}
    sx={{
      fontFamily: 'Inter',
      backgroundColor,
      color: 'white',
      borderRadius: '6px',
      maxHeight: '36px',
      '&:hover': {
        backgroundColor: children === 'Cancel' ? 'lightgray' : '#6a090a',
        ...(children === 'Cancel' ? {color: 'white'} : {})
      },
      '&:disabled': {
        color: 'rgba(0, 0, 0, 0.26)',
        backgroundColor: 'rgba(0, 0, 0, 0.12);',
      },
      ...sx,
    }}
    onClick={onClick}
    disabled={disabled}>
    {children}
  </Button>
);
