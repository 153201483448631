import * as React from 'react';
// import Button from '@mui/material/Button';
import styled from '@emotion/styled';
// import Dialog from '@mui/material/Dialog';
// import DialogTitle from '@mui/material/DialogTitle';
// import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';
// import Typography from '@mui/material/Typography';
import { AccountInformationForm } from 'src/components/account/AccountInformationForm';
import { useFormik } from 'formik';
import {
  Avatar,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  // Divider,
  Typography,
  useTheme,
} from '@material-ui/core';
import useGenericStyles from 'src/styles/useGenericStyles';
import { FileDropzone } from '../fileUpload/FileDropZoneCircular';
import axiosInstance from 'src/utils/axios';
import toast from 'react-hot-toast';
import { CustomButton2 } from '../buttons/CustomButton2';
import * as Yup from 'yup';
import { userApi } from 'src/api/userApi';
import { useAppSelector } from 'src/hooks/useRedux';

type CustomDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
};

const BootstrapDialog = styled(Dialog)(() => ({
  '& .MuiDialogContent-root': {
    padding: '20px',
    maxWidth: '790px',
  },
  '& .MuiPaper-root': {
    borderRadius: '16px',
  },
  '& .MuiDialogActions-root': {
    padding: '20px',
  },
}));

export default function CustomDialog({
  isOpen,
  handleClose,
}: CustomDialogProps) {
  const [loading, setLoading] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const gettingRoles = useAppSelector((state) => state.roles.loading);
  const roles = useAppSelector((state) => state.roles.allRoles);
  const classes = useGenericStyles();
  const theme = useTheme();
  const formFields = [
    {
      type: 'text',
      label: 'First Name',
      name: 'firstName',
      placeholder: 'First Name',
    },
    {
      type: 'text',
      label: 'Last Name',
      name: 'lastName',
      placeholder: 'Last Name',
    },
    {
      type: 'text',
      label: 'Email',
      name: 'email',
      placeholder: 'Email',
    },
    {
      type: 'text',
      label: 'Designation',
      name: 'designation',
      placeholder: 'Designation',
    },
    {
      type: 'text',
      label: 'Phone Number',
      name: 'phoneNumber',
      placeholder: 'Phone Number',
    },
    {
      type: 'dropdown',
      label: 'Role',
      name: 'role',
      placeholder: 'Phone Number',
      options: roles,
      loading: gettingRoles,
      limitTags: 1,
    },
    {
      type: 'text',
      label: 'About',
      name: 'info',
      placeholder: 'About',
      xs: 12,
    },
  ];

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      // userName: '',
      email: '',
      designation: '',
      contactNumber: '',
      info: '',
      avatar: '',
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().trim().required('Field required'),
      lastName: Yup.string().trim().required('Field required'),
      // userName: Yup.string().trim().required('Field required'),
      email: Yup.string()
        .trim()
        .required('Field required')
        .email('Invalid Email'),
      designation: Yup.string().trim().required('Field required'),
      contactNumber: Yup.string().trim().required('Field required'),
      // info: Yup.string().trim().required('Field'),
      // avatar: Yup.string().trim().required('Field'),
    }),
    onSubmit: async (values) => {
      try {
        setSubmitting(true);
        const response = await userApi.createUser(values);
        if (response.status === 201) {
          toast.success('Created Successfully');
          setSubmitting(false);
        }
      } catch (error) {
        setSubmitting(false);
        toast.error('Something went wrong');
      }
    },
  });

  const uploadImage = (file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);

    axiosInstance
      .post(`${process.env.REACT_APP_IMAGE_UPLOAD_URL}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((response) => {
        if (response?.status === 201) {
          setLoading(false);
          formik.setFieldValue('avatar', response?.data?.blob_url);
          toast.success('Attachments uploaded successfully');
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error);
      });
  };

  const handleDrop = React.useCallback((newFiles: File[]) => {
    newFiles.forEach((file) => {
      uploadImage(file);
    });
    // setFiles(() => [...newFiles]);
  }, []);
  // const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <Typography className={classes.smallHeading}>
            Create new contact
          </Typography>
        </DialogTitle>
        {/* <Divider
          sx={{
            // marginBottom: '20px',
            color: '#EDF2F7',
          }}
        /> */}
        <DialogContent dividers>
          {/* <Typography className={classes.smallHeading}>
            Create new contact
          </Typography> */}

          <Box
            sx={{
              display: 'flex',
              width: '100%',
              backgroundColor: '#FFFFFF',
              borderRadius: '16px',
              boxShadow:
                '0px 3px 8px -1px rgba(50, 50, 71, 0.05),  0px 0px 1px 0px rgba(12, 26, 75, 0.24)',
              padding: '20px',
            }}>
            <Avatar
              sx={{
                width: '48px',
                height: '48px',
              }}
              src={formik.values.avatar}>
              {loading ? <CircularProgress color="primary" /> : null}
            </Avatar>
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between',
                marginLeft: '10px',
              }}>
              <Box>
                <Typography className={classes.heading}>
                  Heather Wright
                </Typography>
                <Typography className={classes.description}>
                  PNG or JPG no bigger than 1000px wide and tall.
                </Typography>
              </Box>
              {/* <Button className={classes.button} startIcon={<Upload />}>
            Upload
          </Button> */}
              <FileDropzone
                accept={'image/*'}
                // files={files}
                maxFiles={1}
                onDrop={handleDrop}
              />
            </Box>
          </Box>
          <Box
            sx={{
              marginTop: '20px',
            }}>
            <Typography className={classes.heading}>
              Account Information
            </Typography>
          </Box>
          <AccountInformationForm
            setFieldValue={formik.setFieldValue}
            formFields={formFields}
            rootStyles={{
              margin: '20px 0 ',
            }}
            formikErrors={formik.errors}
            formikValues={formik.values}
            handleChange={formik.handleChange}
          />
        </DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={handleClose}>
            Save changes
          </Button> */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '10px',
            }}>
            <CustomButton2
              // backgroundColor={theme.palette.primary.main}
              sx={{
                color: 'black',
                boxShadow:
                  '0px 3px 8px -1px rgba(50, 50, 71, 0.05), 0px 0px 1px 0px rgba(12, 26, 75, 0.24)',
              }}
              className={classes.button}
              onClick={handleClose}>
              Cancel
            </CustomButton2>
            <CustomButton2
              backgroundColor={theme.palette.primary.main}
              className={classes.button}
              onClick={formik.handleSubmit}>
              {submitting ? (
                <CircularProgress
                  sx={{
                    color: '#FFFFFF',
                  }}
                  size="20px"
                />
              ) : (
                'Save Changes'
              )}
            </CustomButton2>
          </Box>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
