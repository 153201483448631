// src/components/DataTable.js

import styled from '@emotion/styled';
import {
  Box,
  Checkbox,
  IconButton,
  Modal,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from '@material-ui/core';
import React, { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import FilledStart from 'src/icons/FilledStar';
import FilterIcon from 'src/icons/filter';
import { SortAtoZ } from 'src/icons/SortAtoZ';
import { SortZtoA } from 'src/icons/SortZtoA';
import Star from 'src/icons/Star';
import useGenericStyles from 'src/styles/useGenericStyles';
import { CustomButton2 } from '../widgets/buttons/CustomButton2';

interface Sort {
  field: string;
  order: string;
}
interface FilterOption {
  value: string;
  label: string;
}

interface TableProps {
  heading?: string | ReactNode;
  columns: any;
  data: any;
  page?: number;
  limit?: number;
  handleLimit?: (limit: number) => void;
  handlePage?: (page: number) => void;
  totalRecords?: number;
  loading?: boolean;
  showCheckBoxes?: boolean;
  showFavouriteButtons?: boolean;
  getFilterOptions?: (column: string) => FilterOption[];
  handleSort?: (columnField: string) => void;
  sort?: Sort | any;
  handleApplyFilter?: (
    columnName: string,
    filterValue: string,
    filterName: string,
    checked: boolean
  ) => void;
  filters?: any;
  navigateUrl?: string;
  navigateToKey?: boolean;
  favourites?: Array<any>;
  handleClickFavourite?: (favId: string) => void;
  selectRowData?: boolean; // This prop if set to true will make the checkbox select whole row objects rather than only id's
  actionButtonCallback?: (values: any) => void; // This callback is fired when we click on the button in the heading, if its provided
  actionButtonLabel?: string;
  showPagination?: boolean;
}

const CustomLink = styled(Link)({
  textDecoration: 'none',
  color: 'inherit',
  display: 'contents', // Ensures the Link behaves as a span for the TableRow
});

const CustomizedTable = ({
  heading,
  columns,
  data,
  handlePage,
  handleLimit,
  page,
  limit,
  totalRecords,
  loading,
  getFilterOptions,
  showCheckBoxes,
  showFavouriteButtons,
  handleSort,
  sort,
  handleApplyFilter,
  filters,
  navigateUrl,
  navigateToKey,
  favourites,
  handleClickFavourite,
  selectRowData = false,
  actionButtonCallback,
  actionButtonLabel,
  showPagination = true,
}: TableProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentFilterColumn, setCurrentFilterColumn] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const theme = useTheme();
  const [filterAnchorEl, setFilterAnchorEl] = useState(null); // State to track the position of the filter icon
  const [filterOptions, setFilterOptions] = useState([]); // State to hold filter options for the current column

  const classes = useGenericStyles();

  const handleChangePage = (event: any, _page: number) => {
    handlePage(_page);
  };

  const handleChangeLimit = (event: any) => {
    handleLimit(parseInt(event.target.value, 10));
    handlePage(0);
  };

  const handleOpenFilterModal = (event, column) => {
    setCurrentFilterColumn(column.headerName);
    setFilterOptions(getFilterOptions(column.field)); // Function to get filter options based on the column
    setFilterAnchorEl(event.currentTarget.getBoundingClientRect());
    setModalOpen(true);
  };

  const handleCloseFilterModal = () => {
    setModalOpen(false);
    setFilterAnchorEl(null);
  };

  const renderCell = (column, row) => {
    if (column.render) {
      return column.render(row[column.field], row);
    }
    return row[column.field];
  };

  const renderSortIcon = (field) => {
    if (sort.field === field) {
      return sort.order === 'asc' ? <SortAtoZ /> : <SortZtoA />;
    }
    return null;
  };

  const handleSelectRow = (rowObjorId) => {
    // eslint-disable-next-line consistent-return
    setSelectedRows((prevSelectedRows) => {
      if (selectRowData) {
        const isSelected = prevSelectedRows?.find(
          (selectedRow) => selectedRow.id === rowObjorId.id
        );
        if (isSelected) {
          return prevSelectedRows?.filter(
            (selectedRow) => selectedRow.id !== rowObjorId.id
          );
        }
        return [...prevSelectedRows, rowObjorId];
      }
      // in case its selecting id's only
      setSelectedRows((prevSelected_Rows) => {
        if (prevSelected_Rows.includes(rowObjorId)) {
          return prevSelected_Rows.filter((rowId) => rowId !== rowObjorId);
        }
        return [...prevSelected_Rows, rowObjorId];
      });
    });
  };

  // Handle action button click
  const handleActionButtonClick = () => {
    if (actionButtonCallback) {
      actionButtonCallback(selectedRows);
      setSelectedRows([]);
    }
  };

  const isSelected = (rowIdorObj) =>
    selectRowData
      ? selectedRows?.some((selectedRow) => selectedRow.id === rowIdorObj.id)
      : selectedRows?.includes(rowIdorObj);

  const isFavourite = (id) => favourites?.includes(id);

  const handleSelectAllRows = () => {
    if (selectedRows?.length === data?.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(selectRowData ? data : data?.map((row) => row.id));
    }
  };

  const TableRowsLoader = ({
    rowsNum,
    columnsNum,
  }: {
    rowsNum: number;
    columnsNum: number;
  }) => (
    <TableBody>
      {[...Array(rowsNum)].map((index) => (
        <TableRow key={index}>
          <TableCell colSpan={columnsNum}>
            <Skeleton animation="wave" variant="text" />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );

  return (
    <Paper sx={{ borderRadius: '16px' }}>
      {(heading || actionButtonLabel) && (
        <Box
          sx={{
            backgroundColor: '#FFFFFF',
            borderRadius: '16px',
            minHeight: '60px',
            display: 'flex',
            alignItems: 'center',
            padding: '18px 24px',
            justifyContent: 'space-between',
          }}>
          <Typography className={classes.smallHeading} color="#1F2D3D">
            {heading}
          </Typography>
          {actionButtonLabel && actionButtonCallback && (
            <CustomButton2
              disabled={selectedRows?.length < 1}
              onClick={handleActionButtonClick}
              backgroundColor={theme.palette.primary.main}>
              {actionButtonLabel}
            </CustomButton2>
          )}
        </Box>
      )}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {showCheckBoxes && (
                <TableCell
                  sx={{
                    borderRight: '1px solid #EDF2F7',
                    padding: '4px',
                    // width: '18px',
                    // minWidth: '18px',
                    textAlign: 'center'
                  }}
                >
                  <Checkbox
                    indeterminate={
                      selectedRows?.length > 0 &&
                      selectedRows?.length < data?.length
                    }
                    checked={
                      selectedRows?.length > 0 &&
                      selectedRows?.length === data?.length
                    }
                    onChange={handleSelectAllRows}
                    sx={{
                      width: '15px',
                      minWidth: '15px',
                      padding: '2px'
                    }}
                  />
                  {/* </Box> */}
                </TableCell>
              )}
              {showFavouriteButtons && (
                <TableCell
                  padding="checkbox"
                  sx={{
                    padding: '0 10px',
                    borderRight: '1px solid #EDF2F7',
                  }}>
                  <Checkbox checked checkedIcon={<FilledStart />} disabled />
                </TableCell>
              )}
              {columns.map((column) => (
                <TableCell
                  key={column.field}
                  sx={{
                    maxWidth: column.maxWidth || 'auto',
                    textTransform: 'uppercase',
                    // textWrap: 'nowrap',
                    padding: '8px 10px',
                  }}>
                  <Box
                    component="span"
                    sx={{
                      cursor: column.sortable ? 'pointer' : 'default',
                      userSelect: 'none',
                    }}
                    {...(column.sortable && {
                      onClick: () => handleSort(column.field),
                    })}>
                    {column.headerName}
                  </Box>
                  {column.filterable && (
                    <IconButton
                      onClick={(event) => handleOpenFilterModal(event, column)}
                      sx={{
                        padding: 0,
                        marginLeft: '5px',
                      }}>
                      <FilterIcon />
                    </IconButton>
                  )}
                  {column.sortable && (
                    <IconButton
                      disableRipple
                      disableTouchRipple
                      sx={{
                        padding: '0 5px',
                        borderRadius: '5px',
                        cursor: 'default',
                        '&:hover': {
                          backgroundColor: 'transparent',
                        },
                      }}>
                      {renderSortIcon(column.field)}
                    </IconButton>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loading ? (
            <TableRowsLoader
              rowsNum={10}
              columnsNum={
                columns.length +
                (showCheckBoxes ?? 0) +
                (showFavouriteButtons ?? 0)
              }
            />
          ) : (
            <TableBody>
              {data?.length > 0 ? (
                data?.map((row) => (
                  <TableRow
                    key={row.id}
                    component={navigateUrl ? CustomLink : 'tr'}
                    to={
                      navigateUrl
                        ? `${navigateUrl}/${navigateToKey ? row.caseKey : row.id
                        }`
                        : undefined
                    }>
                    {showCheckBoxes && (
                      <TableCell
                        width='18px'
                        sx={{
                          borderRight: '1px solid #EDF2F7',
                          // width: '18px',
                          // minWidth: '18px',
                          padding: '4px',
                          textAlign: 'center'
                        }}
                        onClick={(event: React.MouseEvent) => {
                          event.preventDefault();
                          event.stopPropagation();
                        }}
                      >
                        {/* <Box
                          sx={{
                            width: '18px',
                            minWidth: '18px'
                          }}> */}
                        <Checkbox
                          checked={isSelected(selectRowData ? row : row.id)}
                          onClick={(e) => e.stopPropagation()}
                          onChange={() =>
                            handleSelectRow(selectRowData ? row : row.id)
                          }
                        />
                        {/* </Box> */}
                      </TableCell>
                    )}
                    {showFavouriteButtons && (
                      <TableCell
                        padding="checkbox"
                        sx={{
                          padding: '0 10px',
                          borderRight: '1px solid #EDF2F7',
                          zIndex: '99999',
                        }}
                        onClick={(e) => e.stopPropagation()}>
                        <Checkbox
                          icon={<Star />}
                          checkedIcon={<FilledStart />}
                          checked={isFavourite(row.id)}
                          onClick={(e) => e.stopPropagation()}
                          onChange={() => handleClickFavourite(row.id)}
                        />
                      </TableCell>
                    )}
                    {columns.map((column) => (
                      <TableCell
                        key={column.field}
                        sx={{
                          maxWidth: column.width || 'auto',
                          width: column.width || 'auto',
                          padding: '4px 10px',
                          fontSize: '12px',
                        }}>
                        {renderCell(column, row)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableCell
                  colSpan={
                    columns.length +
                    (showCheckBoxes ?? 0) +
                    (showFavouriteButtons ?? 0)
                  }
                  sx={{
                    textAlign: 'center',
                  }}>
                  {' '}
                  No data found
                </TableCell>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {showPagination && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '18px 24px',
          }}>
          <Typography className={classes.description} color="#7A7A9D!important">
            {`Showing ${data?.length || 0
              } items out of ${totalRecords} results found`}
          </Typography>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100, 500, 1000]} // Adjust as necessary
            component="div"
            count={totalRecords} // totalRecords should be the total number of records from the API
            rowsPerPage={limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeLimit}
            labelDisplayedRows={({ count }) =>
              `Page ${page + 1} of ${Math.ceil(count / limit)}`
            }
          // Either add proper conditions or comment it for now - Sheryar
          // backIconButtonProps={{
          //   disabled: loading,
          // }}
          // nextIconButtonProps={{
          //   disabled: loading,
          // }}
          />
        </Box>
      )}
      <Modal open={modalOpen} onClose={handleCloseFilterModal}>
        <Box
          sx={{
            position: 'absolute',
            top: filterAnchorEl
              ? filterAnchorEl.bottom + window.scrollY
              : '50%',
            left: filterAnchorEl ? filterAnchorEl.left + window.scrollX : '50%',
            transform: 'translateY(0)',
            bgcolor: '#FFFFFF',
            boxShadow: 24,
            borderRadius: '8px',
            minWidth: '214px',
            maxHeight: '50vh',
            overflow: 'auto',
          }}>
          <Box
            className={classes.smallHeading}
            sx={{
              color: '#7A7A9D',
              position: 'sticky',
              top: 0,
              padding: '14px 12px',
              backgroundColor: '#FFFFFF',
              zIndex: 400,
              borderBottom: '1px solid lightgray',
            }}>
            Filter by {currentFilterColumn}
          </Box>
          <Box
            sx={{
              padding: '10px',
              color: '#425466',
            }}>
            {filterOptions.map((option) => (
              <div
                key={option.value}
                className={classes.inputLabel}
                style={{
                  marginBottom: '5px',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <Checkbox
                  checked={filters[currentFilterColumn]?.find(
                    (filterObj) => filterObj.value === option.value
                  )}
                  onChange={(e) =>
                    handleApplyFilter(
                      currentFilterColumn,
                      option.value,
                      option.label,
                      e.target.checked
                    )
                  }
                  sx={{
                    marginRight: '10px',
                  }}
                />
                {option.label}
              </div>
            ))}
          </Box>
        </Box>
      </Modal>
    </Paper>
  );
};

export default CustomizedTable;
