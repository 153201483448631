import { Box } from '@material-ui/core';
import React from 'react';

export const IsActive = ({ isActive }: { isActive: boolean }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
    }}>
    <div
      style={{
        height: '10px',
        width: '10px',
        borderRadius: '50%',
        backgroundColor: isActive ? '#4AAE8C' : '#DB3737',
        marginRight: '10px',
      }}
    />
    {isActive ? 'Active' : 'Inactive'}
  </Box>
);
