import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
  useTheme,
  TextField,
  Theme,
  createStyles,
  makeStyles,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { uniqBy } from 'lodash';
import setTitle from 'src/utils/setTitle';
import gtm from '../../lib/gtm';
import { CustomButton2 } from 'src/components/widgets/buttons/CustomButton2';
import AddIcon from '@material-ui/icons/Add';
import { CasesOverviewCard } from './CasesOverviewCard';
import useGenericStyles from 'src/styles/useGenericStyles';
import { Header } from 'src/components/Header';
import { useNavigate } from 'react-router';
import ReactApexChart from 'react-apexcharts';
import { PieCustomLegend } from 'src/components/widgets/charts/PieCustomLegend';
import { dashboardApi } from 'src/api/dashboardApi';
import toast from 'react-hot-toast';
import hexToRgbA from 'src/utils/hexToRgbA';
import { casesApi } from 'src/api/casesApi';


const dasboardStyles = makeStyles<Theme>(() =>
  createStyles({

    dasboardHeader: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '20px',

    },
    filterControls: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '15px',
      marginTop: '20px', // Add some space between the header and filter controls
    },

  })
);

const Dashboard: FC = () => {
  setTitle('ITSM | Dashboard');
  const [fetchingCountNPercentage, setFetchingCountNPercentage] =
    useState(false);
  const classes = useGenericStyles();
  const style = dasboardStyles();
  const navigate = useNavigate();
  const [isExporting, setIsExporting] = useState(false);
  const theme = useTheme();

  const [sprint, setSprint] = useState<any>({});
  const [sprints, setSprints] = useState<any>([]);

  const [casesData, setCasesCount] = useState({});
  const [totalCasesCount, setTotalCasesCount] = useState<any>({
    'To Do': 0,
    'In Progress': 0,
    'Closed': 0,
    'Awaiting Approval': 0,
    'Waiting For Client': 0,
  });

  const [isPreviousDataChecked, setIsPreviousDataChecked] = useState(false);

  const barChartLegends = [
    {
      name: 'To Do',
      color: theme.palette.primary.main,
    },
    {
      name: 'In Progress',
      color: hexToRgbA(theme.palette.primary.main, 0.8),
    },
    {
      name: 'Closed',
      color: hexToRgbA(theme.palette.primary.main, 0.6),
    },
    {
      name: 'Awaiting Approval',
      color: hexToRgbA(theme.palette.primary.main, 0.4),
    },
    {
      name: 'Waiting For Client',
      color: hexToRgbA(theme.palette.primary.main, 0.2),
    },
  ];

  const [pieChartData, setPieChartData] = useState({
    series: [0, 0, 0, 0, 0],
    options: {
      chart: {
        width: 100,
      },
      stroke: {
        width: 0,
      },
      labels: ['To Do', 'In Progress', 'Closed', 'Awaiting Approval', 'Waiting For Client'],
      tooltip: {
        enabled: false,
        fillSeriesColor: true,
        style: {
          fontSize: '14px',
          color: '#a9f3df',
        },
      },
      plotOptions: {
        pie: {
          customScale: 1,
          dataLabels: {
            offset: 0,
            minAngleToShowLabel: 10,
          },
          donut: {
            labels: {
              show: true,
              name: { show: false },
              value: {
                color: '#27272E',
                fontFamily: 'Inter',
                fontSize: '28px',
                fontWeight: 600,
              },
              total: {
                show: true,
                label: '',
                fontSize: '10px',
                fontFamily: 'Arial, sans-serif',
                color: 'red',
              },

              formatter(w) {
                return w.globals.seriesTotals.reduce((a, b) => a + b, 0);
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      fill: {
        colors: [
          theme.palette.primary.main,
          hexToRgbA(theme.palette.primary.main, 0.8),
          hexToRgbA(theme.palette.primary.main, 0.6),
          hexToRgbA(theme.palette.primary.main, 0.4),
          hexToRgbA(theme.palette.primary.main, 0.2),
        ],
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  });

  const [barChartData, setBarChartData] = useState<any>({
    series: [
      {
        name: 'To Do',
        data: [],
      },
      {
        name: 'In Progress',
        data: [],
      },
      {
        name: 'Closed',
        data: [],
      },
      {
        name: 'Awaiting Approval',
        data: [],
      },
      {
        name: 'Waiting For Client',
        data: [],
      },
    ],
    options: {
      colors: [
        theme.palette.primary.main,
        hexToRgbA(theme.palette.primary.main, 0.8),
        hexToRgbA(theme.palette.primary.main, 0.6),
        hexToRgbA(theme.palette.primary.main, 0.4),
        hexToRgbA(theme.palette.primary.main, 0.2),
      ],
      grid: {
        strokeDashArray: 7,
        show: true,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      chart: {
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '16px',
          borderRadius: 6,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 4,
        colors: ['transparent'],
      },
      xaxis: {
        categories: ['To Do', "In Progress", "Closed", "Awaiting Approval", "Waiting For Client"],
        axisBorder: {
          show: false,
        },
        labels: {
          style: {
            fontFamily: 'Inter',
            fontSize: '12px',
            fontWeight: 400,
            colors: '#7A7A9D',
          },
        },
        axisTicks: { show: false },
      },
      legend: {
        show: false,
      },
      yaxis: {
        labels: {
          style: {
            fontFamily: 'Inter',
            fontSize: '12px',
            fontWeight: 400,
            colors: '#7A7A9D',
          },
          formatter: (value) => Math.round(value).toString(),
        },
      },
      fill: {
        opacity: 1,
      },
    },
  });

  const processDataForBarChart = (data, isPreviousData = false) => {
    console.log("categories", isPreviousData)
    if (isPreviousData) {
      const categories = Object.keys(data);
      const series = [
        {
          name: 'To Do',
          data: categories.map(key => data[key]["To Do"] || 0),
        },
        {
          name: 'In Progress',
          data: categories.map(key => data[key]["In Progress"] || 0),
        },
        {
          name: 'Closed',
          data: categories.map(key => data[key].Closed || 0),
        },
        {
          name: 'Awaiting Approval',
          data: categories.map(key => data[key]["Awaiting Approval"] || 0),
        },
        {
          name: 'Waiting For Client',
          data: categories.map(key => data[key]["Waiting For Client"] || 0),
        },
      ];

      return {
        series,
        options: {
          ...barChartData.options,
          xaxis: {
            categories, // Use the sprint identifiers as categories
          },
        },
      };
    }
    console.log("luli", data["To Do"], data["In Progress"], data.Closed, data['Awaiting Approval'], data['Waiting For Client'])

    return {

      series: [
        {
          name: 'To Do',
          data: [data["To Do"], null, null, null, null],
        },
        {
          name: 'In Progress',
          data: [null, data["In Progress"], null, null, null],
        },
        {
          name: 'Closed',
          data: [null, null, data.Closed, null, null],
        },
        {
          name: 'Awaiting Approval',
          data: [null, null, null, data['Awaiting Approval'], null],
        },
        {
          name: 'Waiting For Client',
          data: [null, null, null, null, data['Waiting For Client']],
        },
      ],
      options: {
        ...barChartData.options,
        xaxis: {
          categories: ['To Do', "In Progress", "Closed", "Awaiting Approval", "Waiting For Client"],
        }
      }
    };
  }

  const processDataForPieChart = (data, isPreviousData = false) => {
    const cumulativeData = {
      'To Do': 0,
      'In Progress': 0,
      'Closed': 0,
      'Awaiting Approval': 0,
      'Waiting For Client': 0,
    };

    if (isPreviousData) {
      Object.keys(data).forEach((key) => {
        cumulativeData['To Do'] += data[key]['To Do'] || 0;
        cumulativeData['In Progress'] += data[key]['In Progress'] || 0;
        cumulativeData.Closed += data[key].Closed || 0;
        cumulativeData['Awaiting Approval'] += data[key]['Awaiting Approval'] || 0;
        cumulativeData['Waiting For Client'] += data[key]['Waiting For Client'] || 0;
      });
    }
    else {
      cumulativeData['To Do'] += data['To Do'] || 0;
      cumulativeData['In Progress'] += data['In Progress'] || 0;
      cumulativeData.Closed += data.Closed || 0;
      cumulativeData['Awaiting Approval'] += data['Awaiting Approval'] || 0;
      cumulativeData['Waiting For Client'] += data['Waiting For Client'] || 0;
    }
    return {
      ...pieChartData,
      series: [
        cumulativeData['To Do'],
        cumulativeData['In Progress'],
        cumulativeData.Closed,
        cumulativeData['Awaiting Approval'],
        cumulativeData['Waiting For Client'],
      ],
    };
  };

  const updateCasesOverviewCard = (data) => {
    const cumulativeData = {
      'To Do': 0,
      'In Progress': 0,
      'Closed': 0,
      'Awaiting Approval': 0,
      'Waiting For Client': 0,
    };

    Object.keys(data).forEach((key) => {
      cumulativeData['To Do'] += data[key]['To Do'] || 0;
      cumulativeData['In Progress'] += data[key]['In Progress'] || 0;
      cumulativeData.Closed += data[key].Closed || 0;
      cumulativeData['Awaiting Approval'] += data[key]['Awaiting Approval'] || 0;
      cumulativeData['Waiting For Client'] += data[key]['Waiting For Client'] || 0;
    });

    setCasesCount(cumulativeData);
    setTotalCasesCount(cumulativeData);
  };


  const handleExport = async () => {
    setIsExporting(true);
    try {
      const response = await dashboardApi.exportDailyCount();
      if (response?.status === 200) {
        const blob = new Blob([response.data]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'data.xlsx';
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      }
      setIsExporting(false);
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
      }
      setIsExporting(false);
    }
  };

  const getCasesCountNPercentage = async (val) => {
    setFetchingCountNPercentage(true);
    try {
      if (val.startDate && val.endDate) {
        const response = await dashboardApi.getCasesCountNPercentageV3(val);
        if (response?.status === 200) {
          setCasesCount(response.data);
          setTotalCasesCount(response.data);

          const barChartProcessedData = processDataForBarChart(response.data);
          setBarChartData(barChartProcessedData);


          const pieChartProcessedData = processDataForPieChart(response.data);

          setPieChartData(pieChartProcessedData);
        }
      }
      setFetchingCountNPercentage(false);
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
      }
      setFetchingCountNPercentage(false);
    }
  };

  const getPreviousSprintData = async () => {
    setFetchingCountNPercentage(true);
    try {
      const response = await dashboardApi.getPreviousSprintData();  // Replace with the correct API call
      if (response?.status === 200) {
        const barChartProcessedData = processDataForBarChart(response.data, true);
        setBarChartData(barChartProcessedData);
        const pieChartProcessedData = processDataForPieChart(response.data, true);
        setPieChartData(pieChartProcessedData);

        updateCasesOverviewCard(response.data);
      }
      setFetchingCountNPercentage(false);
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
      }
      setFetchingCountNPercentage(false);
    }
  };

  const handleSprintChange = (e) => {
    const res = sprints?.filter((item) => item?.name === e.target.value);
    if (res?.length > 0) {
      setSprint(res[0]);
    }
  };

  const handleCheckboxChange = (e) => {
    setIsPreviousDataChecked(e.target.checked);
  };

  useEffect(() => {
    if (isPreviousDataChecked) {
      getPreviousSprintData();
    } else {
      getCasesCountNPercentage(sprint);
    }
  }, [sprint, isPreviousDataChecked]);

  const getSprintsByOrganization = async () => {
    try {
      let response = await casesApi.getSprintsByOrganization();
      if (response.status === 200) {
        response = response?.data;
        const uniqueSprintNames = uniqBy(
          response,
          (item: any) => item?.startDate && item?.endDate
        );
        if (uniqueSprintNames?.length > 0) {
          setSprint(uniqueSprintNames[0]);
        }
        setSprints(uniqueSprintNames);
      }
    } catch (error) {
      setSprints([]);
    }
  };

  const filteredKeys = ["Closed", "In Progress", "To Do", 'Awaiting Approval', 'Waiting For Client'];

  useEffect(() => {
    gtm.push({ event: 'page_view' });
    getSprintsByOrganization()
  }, []);

  return (
    <>
      <Header title="Dashboard">
        <Box className={style.dasboardHeader}>
          <CustomButton2
            backgroundColor={theme.palette.primary.main}
            className={classes.button}
            onClick={() => navigate('/cases/add')}>
            <AddIcon
              sx={{
                marginRight: '5px',
              }}
            />
            Create Case
          </CustomButton2>

        </Box>
      </Header>
      <Box className={style.filterControls}>
        {sprints.length > 0 &&
          <TextField
            InputProps={{
              classes: {
                root: classes.textField,
              },
            }}
            size="small"
            label="Sprint"
            placeholder="Sprint"
            name="sprint"
            required
            onChange={handleSprintChange}
            select
            SelectProps={{ native: true }}
            variant="outlined"
            value={sprint?.name || ''}
            defaultValue={sprint?.name || ''}>
            {sprints?.map((item) => (
              <option key={item?.name} value={item?.name}>
                {item?.name}
              </option>
            ))}
          </TextField>
        }
        <FormControlLabel
          control={
            <Checkbox
              checked={isPreviousDataChecked}
              onChange={handleCheckboxChange}
              name="previousData"
              color="primary"
            />
          }
          label="Show Previous Sprint Data"
        />
      </Box>

      <Container maxWidth={false} className={classes.container}>
        <Grid container>
          <Grid container item spacing={3}>

            {filteredKeys.map((keyName) => (
              <Grid item xs={2} key={keyName}>
                <CasesOverviewCard
                  loading={fetchingCountNPercentage}
                  title={keyName}
                  number={casesData[keyName]}
                  percentage=''
                />
              </Grid>
            ))}
          </Grid>

          <Grid marginTop={2} container item spacing={3}>
            <Grid item xs={9}>
              <Box
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '16px',
                  boxShadow:
                    '0px 3px 8px -1px #3232470D, 0px 0px 1px 0px #0C1A4B3D',
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '24px',
                    paddingBottom: '10px',
                    justifyContent: 'space-between',
                  }}>
                  <Typography
                    className={classes.headerHeading}
                    sx={{
                      fontSize: '16px!important',
                    }}>
                    Case overview
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        marginRight: '100px',
                      }}>
                      {barChartLegends.map((obj) => (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '50px',
                          }}>
                          <Box
                            sx={{
                              borderRadius: '50%',
                              backgroundColor: obj.color,
                              width: '8px',
                              height: '8px',
                            }}
                          />

                          <Typography
                            sx={{
                              fontFamily: 'Inter',
                              fontSize: 12,
                              fontWeight: 700,
                              color: '#7A7A9D',
                              marginLeft: '10px',
                            }}>
                            {obj.name}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                    <CustomButton2
                      backgroundColor={theme.palette.primary.main}
                      sx={{
                        fontSize: '12px',
                        fontWeight: 600,
                      }}
                      onClick={handleExport}>
                      {isExporting ? (
                        <CircularProgress
                          sx={{
                            color: '#FFFFFF',
                          }}
                          size="20px"
                        />
                      ) : (
                        'Export'
                      )}
                    </CustomButton2>
                  </Box>
                </Box>
                <ReactApexChart
                  options={barChartData.options}
                  series={barChartData.series}
                  type="bar"
                  height={500}
                />
                {
                  console.log("barChartData", barChartData)
                }
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '16px',
                  boxShadow:
                    '0px 3px 8px -1px #3232470D, 0px 0px 1px 0px #0C1A4B3D',
                }}>
                <Box
                  sx={{
                    padding: '24px ',
                  }}>
                  <Typography
                    variant="h4"
                    className={classes.headerHeading}
                    sx={{
                      fontSize: '16px!important',
                    }}>
                    Cases
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-evenly',
                      }}>
                      <PieCustomLegend
                        name="To Do"
                        value={totalCasesCount['To Do']}
                        color={theme.palette.primary.main}
                      />
                      <PieCustomLegend
                        name="In Progress"
                        value={totalCasesCount['In Progress']}
                        color={hexToRgbA(theme.palette.primary.main, 0.8)}
                      />
                      <PieCustomLegend
                        name="Closed"
                        value={totalCasesCount.Closed}
                        color={hexToRgbA(theme.palette.primary.main, 0.6)}
                      />
                      <PieCustomLegend
                        name="Awaiting Approval"
                        value={totalCasesCount['Awaiting Approval']}
                        color={hexToRgbA(theme.palette.primary.main, 0.4)}
                      />
                      <PieCustomLegend
                        name="Waiting For Client"
                        value={totalCasesCount['Waiting For Client']}
                        color={hexToRgbA(theme.palette.primary.main, 0.2)}
                      />
                    </Box>
                    <ReactApexChart
                      options={pieChartData.options}
                      series={pieChartData.series}
                      type="donut"
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Dashboard;
