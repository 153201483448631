/* eslint-disable implicit-arrow-linebreak */
import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router';
import AuthGuard from './components/AuthGuard';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import DashboardLayout from './components/dashboard/DashboardLayout';
import HelpLayout from './components/dashboard/HelpLayout';
import ProjectSection from './components/organization/projects/ProjectSection';
import CasesViewByProjects from './components/organization/projects/cases/CasesViewByProjects';
import Account from './pages/Account';
import EditCases from './pages/cases/EditCases';
import UnApprovedCases from './pages/cases/UnApprovedCases';
import ViewCases from './pages/cases/ViewCases';
import ContactsListView from './pages/contacts/listView';
import Overview from './pages/dashboard/Overview';
import Contacts from './pages/help/Contacts';
import Dashboard from './pages/help/Dashboard';
import Introduction from './pages/help/Introduction';
import Project from './pages/help/Project';
import HelpCases from './pages/help/cases/HelpCases';
import HelpCreateCases from './pages/help/cases/HelpCreateCases';
import HelpViewCases from './pages/help/cases/HelpViewCases';
import ViewReporting from './pages/reporting/ViewReporting';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

const CreateCasesWizard = Loadable(
  lazy(() => import('./components/cases/CreateCasesWizard'))
);
// const ContactsListView = Loadable(
//   lazy(() => import('./pages/contacts/listView'))
// );
const EditContacts = Loadable(lazy(() => import('./pages/contacts/edit')));
const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(
  lazy(() => import('./pages/authentication/PasswordRecovery'))
);
const PasswordReset = Loadable(
  lazy(() => import('./pages/authentication/PasswordReset'))
);
const AuthorizationRequired = Loadable(
  lazy(() => import('./pages/AuthorizationRequired'))
);
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// eslint-disable-next-line import/no-mutable-exports
let routes: any[] = [];

routes = [
  {
    path: '',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      // ---> Dashboard
      {
        index: true,
        element: <Navigate to="dashboard" />,
      },
      {
        path: 'dashboard',
        element: <Overview />,
      },
      // ---> Analytics
      // {
      //   path: 'analytics',
      //   element: <Analytics />,
      // },
      // ---> Contacts
      {
        path: 'contacts',
        element: <ContactsListView />,
      },
      {
        path: 'contacts/edit/:id',
        element: <EditContacts />,
      },
      // ---> Reporting
      // {
      //   path: 'reporting',
      //   element: <Reporting />,
      // },
      // ---> Settings
      // {
      //   path: 'settings',
      //   element: <Settings />,
      // },
      // ---> Account
      {
        path: 'account',
        element: <Account />,
      },
      // ---> Projects
      {
        path: 'projects',
        element: <ProjectSection />,
      },
      // ---> Billable Reportings
      {
        path: 'reporting',
        element: <ViewReporting />,
      },
      {
        path: 'projects/:projectID',
        element: <CasesViewByProjects />,
        // children: [
        //   {
        //     // index: true,
        //     path: 'cases',
        //     element: <CasesViewByProjects />,
        //   },
        //   {
        //     path: 'cases/add',
        //     element: <CreateCasesWizardProject />,
        //   },
        //   {
        //     path: 'cases/:caseID',
        //     element: <EditCases orgEditComponent />,
        //   },
        // ],
      },
      // ---> Cases
      {
        path: 'cases',
        element: <ViewCases />,
      },
      {
        path: 'approvals',
        element: <UnApprovedCases />,
      },
      {
        path: 'cases/add',
        element: <CreateCasesWizard />,
      },
      {
        path: 'cases/:caseID',
        element: <EditCases />,
      },
    ],
  },
  {
    path: 'help',
    element: (
      <AuthGuard>
        <HelpLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <Introduction />,
      },
      {
        path: 'dashboard',
        element: <Dashboard />,
      },
      {
        path: 'project',
        element: <Project />,
      },
      {
        path: 'contacts',
        element: <Contacts />,
      },
      // {
      //   path: 'multi-lang-support',
      //   element: <MultiLangSupport />,
      // },
      {
        path: 'cases',
        children: [
          {
            index: true,
            element: <HelpCases />,
          },
          {
            path: 'create',
            element: <HelpCreateCases />,
          },
          {
            path: 'view',
            element: <HelpViewCases />,
          },
        ],
      },
    ],
  },

  // ---> Others
  {
    path: '*',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: 'forgot-password',
        element: <PasswordRecovery />,
      },
      {
        path: 'reset-password',
        element: <PasswordReset />,
      },
      {
        path: '401',
        element: <AuthorizationRequired />,
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: '500',
        element: <ServerError />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
