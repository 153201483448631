/* eslint no-underscore-dangle: 0 */

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  // FormControlLabel,
  Grid,
  List,
  ListItem,
  makeStyles,
  // Switch,
  Tab,
  Tabs,
  Theme,
  // Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import CancelIcon from '@material-ui/icons/Cancel';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
// import TranslateIcon from '@material-ui/icons/Translate';
import parseHTML from 'html-react-parser';
import moment from 'moment';
import { /* useCallback, */ useEffect, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { casesApi } from 'src/api/casesApi';
import { translationApi } from 'src/api/translateApi';
import { userApi } from 'src/api/userApi';
import SimpleBreadCrumb from 'src/components/widgets/BreadCrumb/SimpleBreadCrumb';
import CustomButton from 'src/components/widgets/buttons/CustomButton';
import { UserActivity } from 'src/components/widgets/display-blocks/user-activity';
import FileUploadDragDrop from 'src/components/widgets/fileUpload/FileUploadDragDrop';
import DocumentViewer from 'src/components/widgets/modal/DocumentViewer';
import SImpleModal from 'src/components/widgets/modal/SimpleModal';
import useAuth from 'src/hooks/useAuth';
import useSettings from 'src/hooks/useSettings';
import setTitle from 'src/utils/setTitle';
import time from 'src/utils/time';
import ApproveDialog from './ApproveDialog';
import DeclineDialog from './DeclineDialog';
import logger from 'src/utils/logger';

const smallShadow = '0px 0px 4px 0px rgba(0, 0, 0, 0.25)';
export const editCaseStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    '.MuiPaper-root-MuiCard-root': {
      boxShadow: smallShadow,
    },
    select: {
      backgroundColor: '#FFECEC',
      color: '#E53E3E',
      fontWeight: '700',
      '& .MuiSelect-select': {
        padding: '8px 16px',
      },
      '&.Mui-disabled': {
        backgroundColor: '#FFECEC',
        color: '#E53E3E',
      },
    },
    leftSideBarRoot: {
      padding: '10px 10px',
    },
    label: {
      letterSpacing: ' 0.15px',
      color: '#231F20',
      fontWeight: '600',
      paddingBottom: '6px',
      display: 'block',
      fontSize: '14px',
    },
    meta: {
      width: '100%',
      gap: 8,
      fontSize: '14px',
      marginBottom: 8,
      fontWeight: 500,
      color: theme.palette.grey[500],
      '& .author': {
        fontWeight: 600,
        color: theme.palette.grey[600],
      },
    },
    customRadio: {
      '&.Mui-checked': {
        color: theme.palette.primary.main,
      },
    },

    box: {
      '& .MuiAutocomplete-root': {
        '& .Mui-disabled': {
          cursor: 'not-allowed',
        },
      },
      '& .orgEditCase': {
        padding: 0,
        '& .MuiAccordion-root': {
          '& .MuiAccordionDetails-root': {},
        },
        '& .MuiAccordionDetails-root': {
          padding: '16px',
          background: '#fff!important',
          borderRadius: '0 0 4px 4px',
        },
        '& .MuiAccordionSummary-root': {
          background: '#fff !important',
          color: '#231F20 !important',
          borderRadius: '4px 4px 0 0',
          padding: '0 16px',
          flexDirection: 'initial',
        },
      },
      '& .MuiAccordionSummary-expandIconWrapper ': {
        color: '#000 !important',
      },
      '& .boxSub': {
        background: '#fff',
        padding: '16px !important',
        boxShadow: smallShadow,
        borderRadius: '4px',
        '& label': {
          color: '#231f20',
          fontSize: '14px',
          fontWeight: 600,
        },
        '& .boxHeader': {
          background: '#fff',
          color: '#231F20',
          padding: '7px 24px',
          borderRadius: '4px 4px  0  0',
          height: '39px',
        },

        '& p': {
          margin: '10px 0 10px',
        },
        '& .editorMarkup': {
          '& .wmde-markdown-var': {
            border: '1px solid transparent',
          },
        },
        '& .editorMarkupError': {
          '& .wmde-markdown-var': {
            border: `1px solid ${theme.palette.primary.main}`,
          },
        },
        '& .btnComments': {
          margin: '16px 0 0',
          '& button+button': {
            marginLeft: '12px',
          },
        },
        '& .MuiFormHelperText-root': {
          margin: 0,
        },
      },
      '& .commentBoxRoot': {
        marginTop: '25px',
      },
      '& .commentBox': {
        marginTop: '10px',
        padding: '10px 25px',
        border: '1px solid #eceaea',
        borderRadius: '5px',
        '& .commentBoxInner': {
          display: 'flex',
          alignItems: 'start',

          '& h4': {
            fontSize: '14px',
            fontWeight: 'bold',
            margin: 0,
          },
          '& span': {
            fontSize: '14px',
            marginLeft: '10px',
          },
        },
        '& p': {
          margin: '0px 0px 0px 0px !important',
        },
        '& span': {
          fontSize: '14px',
        },
        '& span+span': {
          marginLeft: '10px',
        },
      },
      '& .commentBox.current': {
        border: '1px solid #231f20',
      },
      '& select, & .MuiAutocomplete-inputRoot, & input': {
        height: '38px',
        padding: '0 10px',
        lineHeight: '116.7%',
        color: '#696969',
        width: '100%',
        '& .MuiAutocomplete-input': {
          padding: 0,
        },
      },
      '& .customField': {
        height: '38px',
        padding: '0 10px',
        lineHeight: '116.7%',
        color: '#696969',
        width: '100%',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        '& .MuiChip-root': {
          border: 0,
          borderRadius: '4px',
          margin: '2px 0',
          background: 'rgb(241 240 240)',
        },
      },
      '& .labelsDataCss .MuiAutocomplete-inputRoot': {
        height: 'initial',
      },

      '& .labelField': {
        // width: '50%',
      },
      '& .caseRightInner+.caseRightInner': {
        marginTop: '8px',
      },
      '& .sLa': {
        display: 'flex',
        fontSize: '14px',
        alignItems: 'center',
        '& .btnTime': {
          width: '179px',
          height: '36px',
          borderRadius: '43px',
          background: '#B0B0B0',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& svg': {
            marginLeft: '10px',
          },
        },
        '& .btnTime.bgRed': {
          background: '#CC4D53',
        },
        '& .btnTimeFirst': {
          width: '179px',
          height: '36px',
          borderRadius: '43px',
          background: '#B0B0B0',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& svg': {
            marginLeft: '10px',
          },
        },
        '& .btnTimeFirst.bgRed': {
          background: '#CC4D53',
        },
        '& .resolveTime': {
          fontSize: '14px',
          fontWeight: 'bold',
          marginLeft: '10px',
          '& p': {
            fontSize: '12px',
            fontWeight: 'normal',
            margin: '2px 0 0',
          },
          '& svg': {
            marginLeft: '10px',
          },
        },
      },
      '& .jiraSync': {
        marginTop: '0px',
        '& b': {
          fontSize: '14px',
        },
        fontSize: '12px',
      },
      '& .escalations': {
        display: 'flex',
        '& span': {
          fontSize: '14px',
        },
      },
      '& .rightSide': {
        boxShadow: smallShadow,
        '& hr': {
          border: 0,
          borderTop: '0.8px solid #E0E0E0',
          margin: '15px 0',
          borderWidth: '0.8px',
        },
      },
      '& .rightAccordion': {
        '& .MuiTypography-h4': {
          color: '#231f20',
          fontSize: '14px',
          fontWeight: 600,
        },
        '& label': {
          color: '#231f20',
          fontSize: '12px',
          fontWeight: 600,
        },
      },
      '& .createdAt': {
        fontSize: '14px',
        color: '#777',
        '& p': {
          margin: '0',
        },
        '& span': {
          fontWeight: 500,
        },
      },
      '& .btnCommentInner': {
        marginTop: 2,
        display: 'flex',
        position: 'absolute',
        top: 10,
        right: 10,
        gap: '8px',
        '& button': {
          display: 'flex',
          cursor: 'pointer',
          border: '1px solid transparent',
          borderRadius: '3px',
          color: '#696969',
          backgroundColor: 'rgba(0 0 0/0)',
          transition: 'all 300ms',
          '&:hover': {
            backgroundColor: 'rgba(0 0 0/0.1)',
          },
        },
      },
      '& .attachmentsDiv': {
        marginTop: '30px',
        '& .editCommentFileParent': {
          display: 'flex',
          justifyContent: 'flex-start',
          flexWrap: 'wrap',
          gap: '15px',
          '& a': {
            position: 'relative',
            display: 'inline-flex',
            borderRadius: 4,
            marginBottom: 8,
            width: 200,
            height: 200,
            padding: 4,
            color: ' #757575',
            boxSizing: 'border-box',
            justifyContent: 'center',
            alignItems: 'center',
            border: `1px solid ${theme.palette.grey[300]}`,
            cursor: 'pointer',
            '&  .editCommentFileEdit': {
              position: 'absolute',
              top: '-10px',
              right: '-10px',
              cursor: 'pointer',
              color: theme.palette.primary.main,
            },
            '&  span': {
              position: 'absolute',
              background: ' #f7f7f7',
              fontSize: '10px',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%)',
              padding: '2px',
              fontWeight: 'bold',
            },
          },
        },
        '& a img': {
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        },
        '& img': {
          width: ' 60px',
          height: '67px',
          objectFit: 'contain',
        },
      },
    },
    commentHelperText: {
      margin: '0px',
    },
    commentsTabRoot: {
      marginTop: '15px',
      borderRadius: '4px 4px 0 0',
      '& .MuiTabs-flexContainer': {
        backgroundColor: '#ddd',
        height: '39px',
        border: 'none',
      },
      '& .Mui-selected': {
        '& .MuiTab-wrapper': {
          color: '#333',
        },
      },
      '& .MuiButtonBase-root.Mui-selected[aria-selected="true"]': {
        fontWeight: '700',
        backgroundColor: 'transparent !important',
        border: 'none !important',
      },
      '& .MuiButtonBase-root': {
        textTransform: 'capitalize',
        color: '#333',
        fontWeight: 400,
        fontSize: '1rem',
        minHeight: '39px!important',
        minWidth: '155px !important',
      },
      '& .MuiButtonBase-root:first-child': {
        marginLeft: 0,
      },
      '& .MuiTabs-indicator': {
        backgroundColor: `${theme.palette.primary.main} !important`,
        height: '4px!important',
        top: '39px!important',
        display: 'block!important',
      },
    },
    commentsPreview: {
      // marginLeft: '50px',
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      '& p': {
        margin: '0px!important',
      },
      '& .MuiAvatar-root': {
        backgroundColor: '#231f20',
        width: '40px',
        height: '40px',
      },
      '& img': {
        width: ' 60px',
        height: '67px',
        objectFit: 'contain',
        position: 'relative',
        display: 'block',
        color: ' #757575',
        cursor: 'pointer',
        border: `1px solid ${theme.palette.grey[300]}`,
      },
      '& .MuiListItemText-root': {
        margin: 0,
        fontSize: '14px',
        fontWeight: 300,
        '& strong': {
          color: '#000',
        },
      },
    },
    timelineRoot: {
      margin: 0,
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      '& p': {
        margin: '0px!important',
        wordBreak: 'break-all',
      },
      '& .MuiAvatar-root': {
        backgroundColor: '#231f20',
        width: '40px',
        height: '40px',
      },
      '& img': {
        width: ' 60px',
        height: '67px',
        objectFit: 'contain',
      },
      '& .MuiListItemText-root': {
        margin: 0,
        fontSize: '14px',
        fontWeight: 300,
        '& strong': {
          color: '#000',
        },
      },
    },

    detailsRoot: {
      display: 'grid',
      gridTemplateColumns: '49% 49%',
      rowGap: '5px',
      columnGap: '10px',
      '& .MuiAutocomplete-root .MuiChip-root': {
        maxWidth: '115px',
      },
      '& .MuiAutocomplete-root .MuiAutocomplete-tag': {
        fontSize: '12px',
      },
      [theme.breakpoints.down(1850)]: {
        gridTemplateColumns: '1fr',
      },
    },
    transalationBtn: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      cursor: 'pointer',
    },
    externalComment: {
      backgroundColor: '#fff9f9',
      boxShadow: '0px 0px 1px 1px #ffb0b0',
    },
    commentEditorContainer: {
      border: '2px solid white',
      borderRadius: 4,
      '& .editCommentFileParent': {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        gap: '15px',
        '& a': {
          position: 'relative',
          display: 'block',
          color: ' #757575',
          cursor: 'pointer',
          border: `1px solid ${theme.palette.grey[300]}`,
          '&  .editCommentFileEdit': {
            position: 'absolute',
            top: '-10px',
            right: '-10px',
            cursor: 'pointer',
            color: theme.palette.primary.main,
          },
          '&  span': {
            position: 'absolute',
            background: ' #f7f7f7',
            fontSize: '10px',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%)',
            padding: '2px',
            fontWeight: 'bold',
          },
          '& img': {
            width: ' 60px',
            height: '67px',
            objectFit: 'contain',
          },
        },
      },
    },
    commentOptionButtons: {
      display: 'flex',
      padding: '10px',
      gap: '10px',
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: 4,
      '& .internalCommentLink': {
        cursor: 'pointer',
        paddingBottom: '5px',
        textDecoration: 'underline',
        textUnderlineOffset: '3px',
        // transition: '0.3s linear',
        '&:hover': {
          textDecoration: 'underline #f5f5f5',
          // color: '#f5f5f5'
        },
      },
      '& .externalCommentLink': {
        cursor: 'pointer',
        paddingBottom: '5px',
        textDecoration: 'underline',
        textUnderlineOffset: '3px',
        '&:hover': {
          textDecoration: 'underline #f5f5f5',
          // color: '#f5f5f5'
        },
      },
    },
    customClassAddComment: {
      padding: '0px 5px',
      width: 160,
    },
    approveCaseButton: {
      padding: '0px 5px',
      margin: '0px 15px',
      // width: 160,
    },
    declineCaseButton: {
      padding: '0px 5px',
      marginRight: '15px',
      // width: 160,
    },
    declineButtonRed: {
      backgroundColor: 'red',
      color: 'white',
      border: `2px solid red`,
      '&:hover': {
        color: 'white',
        backgroundColor: 'darkred',
        border: `2px solid darkred`,
      },
    },
  })
);

const useStyles = editCaseStyles;

type TimelineTypes = {
  id: string;
  description: string;
  createdBy: {
    id: string;
    name: string;
  };
  createdAt: string;
  changeFrom?: string | null;
  changeTo?: string | null;
  change: string | null;
  isExternal?: boolean;
  attachmentPath?: string;
};

type TimelineSectionTypes = {
  user: any;
  data: TimelineTypes[];
  caseData: any;
  toggleIsLoad: () => void;
  languageType?: string;
  showTranslation?: any;
  handleDocumentClick?: any;
  handleImageClick?: any;
};

export const Comments = ({
  toggleIsLoad,
  caseData,
  user,
  // languageType,
  // showTranslation,
  handleDocumentClick,
  handleImageClick,
}: any) => {
  const classes = useStyles();
  const commentRef = useRef(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [commentValue, setCommentValue] = useState<string>('');
  const [commentEdit, setCommentEdit] = useState<any>({});
  const [filePath, setFilePath] = useState<string[]>([]);
  const isExternalComment = true;
  const [commentDeleteId, setCommentDeleteId] = useState<string>('');

  const isNotEmpty = (val: string | any[]) => {
    if (Array.isArray(val)) {
      return val.length > 0;
    }
    return typeof val === 'string' && val.trim() !== '';
  };
  const handleSaveComment = async (e) => {
    e.preventDefault();
    try {
      if (isNotEmpty(commentValue) || isNotEmpty(filePath)) {
        const payload = {
          caseId: caseData?.id,
          description: commentValue,
          isExternal: isExternalComment,
          attachmentPath: filePath,
        };
        const response = await casesApi?.createComment(payload);
        if (response?.status === 201) {
          toast.success('Comment Added');
          setCommentValue('');
          setFilePath([]);
          toggleIsLoad();
        }
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  const deleteComment = async (caseId: String) => {
    try {
      const response = await casesApi?.deleteComments(caseId, commentDeleteId);
      if (response?.status === 200) {
        toast.success('Comment Deleted');
        toggleIsLoad();
        setOpenDialog(false);
        toggleIsLoad();
        if (isEditing) {
          setIsEditing(false);
          setCommentValue('');
          setCommentEdit({
            commentId: '',
          });
        }
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  const editCommentAPI = async (e) => {
    e.preventDefault();
    try {
      if (isNotEmpty(commentValue)) {
        const payload = {
          caseId: commentEdit?.caseId,
          description: commentValue,
          attachmentPath: filePath,
        };
        const response = await casesApi?.editCaseComment(
          commentEdit?.commentID,
          payload
        );
        if (response?.status === 200) {
          toast.success('Comment Updated');
          toggleIsLoad();
          setCommentValue('');
          setIsEditing(false);
        }
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  const editComment = async (
    caseIDs: string,
    commentId: string,
    commentDes: string,
    indexMap: Number,
    filePaths: string[]
  ) => {
    setIsEditing(true);
    setFilePath(filePaths);
    setCommentValue(commentDes);
    setCommentEdit({
      caseId: caseIDs,
      commentID: commentId,
      currentIndex: indexMap,
    });
  };
  const handleEditCommentDeleteFile = (e, index) => {
    e.stopPropagation();
    const updatedFiles = [...filePath];
    updatedFiles.splice(index, 1);
    setFilePath(updatedFiles);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  // const handleCheckExternalComment = () => {
  //   setIsExternalComment(!isExternalComment);
  // };

  function uploadAdapter(loader) {
    return {
      upload: () =>
        new Promise((resolve) => {
          loader.file.then(async (file) => {
            const formData = new FormData();
            formData.append('file', file);
            const image = await userApi.uploadAttachments(formData);
            resolve({
              default: `${image?.data?.blob_url}`,
            });
          });
        }),
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) =>
      uploadAdapter(loader);
  }

  const modifyImgAttributes = (node) => {
    if (node.name === 'img') {
      // Check if src attribute is present
      if (
        node.attribs &&
        node.attribs.src &&
        node.attribs.src.includes('backupdbaccount.blob.core.windows.net')
      ) {
        // Modify the src attribute as per your requirement
        const splittedUrl = node.attribs.src?.split('?');
        splittedUrl[1] =
          'sp=racwdl&st=2024-02-27T13:21:58Z&se=2030-07-29T21:21:58Z&spr=https&sv=2022-11-02&sr=c&sig=YXOHT7%2Bx8VW3RDdgzehtPZLWJ6zHJz%2Bp3QtnduafL4o%3D';
        node.attribs.src = splittedUrl?.join('?');
      }
    }
    return node;
  };

  return (
    <Box padding="17px 0 24px">
      <div
        style={{ flexGrow: 1, marginBottom: 16 }}
        data-color-mode="light"
        className="editorMarkup"
        ref={commentRef}>
        <div className={classes.commentEditorContainer}>
          <CKEditor
            editor={ClassicEditor}
            data={commentValue}
            onChange={(event, editor) => {
              const data = editor.getData();
              setCommentValue(data);
              // Create a temporary DOM element to parse the content
              // const tempDiv = document.createElement('div');
              // tempDiv.innerHTML = data;

              // // Extract all image elements
              // const imageElements = tempDiv.querySelectorAll('img');

              // // Extract image sources
              // const imageSources = Array.from(imageElements).map((image) => image?.src);
              // setFilePath((prev) => [...prev, imageSources.length > 0 ? imageSources : '']);
              // setCommentValue(data);
            }}
            config={{
              placeholder: 'Enter your comment here',
              toolbar: [
                'heading',
                '|',
                'bold',
                'italic',
                'link',
                'bulletedList',
                'numberedList',
                'blockQuote',
                'uploadimage',
              ],
              extraPlugins: [uploadPlugin],
            }}
          />
          <FileUploadDragDrop setFilePath={setFilePath} filePath={filePath} />
          {isEditing && (
            <div className="editCommentFileParent">
              {isEditing &&
                filePath?.map((item, index) => {
                  const splittedUrl = item?.split('?');
                  splittedUrl[1] =
                    'sp=racwdl&st=2024-02-27T13:21:58Z&se=2030-07-29T21:21:58Z&spr=https&sv=2022-11-02&sr=c&sig=YXOHT7%2Bx8VW3RDdgzehtPZLWJ6zHJz%2Bp3QtnduafL4o%3D';
                  item = splittedUrl?.join('?');
                  const getFileType = (items) => {
                    if (items.includes('.pdf')) {
                      return 'PDF';
                    }
                    if (items.includes('.doc')) {
                      return 'DOC';
                    }
                    if (items.includes('.docx')) {
                      return 'DOCX';
                    }
                    if (items.includes('.txt')) {
                      return 'TXT';
                    }
                    return null;
                  };

                  const fileType = getFileType(item);
                  if (
                    item.includes('.pdf') ||
                    item.includes('.doc') ||
                    item.includes('.docx') ||
                    item.includes('.txt')
                  ) {
                    return (
                      <a
                        role="presentation"
                        onClick={() => handleDocumentClick(item)}>
                        {' '}
                        <span>{fileType}</span>
                        <InsertDriveFileIcon sx={{ fontSize: '60px' }} />
                        <CancelIcon
                          onClick={(e) => handleEditCommentDeleteFile(e, index)}
                          className="editCommentFileEdit"
                        />
                      </a>
                    );
                  }
                  const imageFormats = [
                    '.jpg',
                    '.jpeg',
                    '.png',
                    '.gif',
                    '.bmp',
                    '.tiff',
                    '.webp',
                    '.svg',
                    '.ico',
                  ];
                  if (
                    imageFormats.some((extension) => item?.includes(extension))
                  ) {
                    return (
                      <>
                        <a
                          role="presentation"
                          onClick={() => handleImageClick(item)}>
                          <img src={item} alt="img" />
                          <CancelIcon
                            onClick={(e) =>
                              handleEditCommentDeleteFile(e, index)
                            }
                            className="editCommentFileEdit"
                          />
                        </a>
                      </>
                    );
                  }
                  return null;
                })}
            </div>
          )}
        </div>
        <div className="btnComments">
          {!isEditing ? (
            <CustomButton
              value={isExternalComment ? 'Add Comment' : 'Add Comment'}
              customClass={classes.customClassAddComment}
              color="secondary"
              onClick={handleSaveComment}
            />
          ) : (
            <>
              <CustomButton
                value="Update Comment"
                color="secondary"
                style={{ width: '160px !important' }}
                onClick={(e) => editCommentAPI(e)}
              />
            </>
          )}
        </div>
      </div>
      {caseData?.comment?.length !== 0 && (
        <>
          <Typography
            variant="body2"
            sx={{
              fontSize: '16px',
              fontWeight: 600,
              margin: '0px 0px 8px 0px',
            }}>
            Comments
          </Typography>
          <List className={classes.commentsPreview}>
            {caseData?.comment
              ?.filter((comment) => comment?.isExternal)
              ?.map((comment, i) =>
              // function extractTextFromHTML(htmlString) {
              //   // Create a new DOMParser
              //   const parser = new DOMParser();

                //   // Parse the HTML string
                //   const doc = parser.parseFromString(htmlString, 'text/html');

                //   // Get the text content from the parsed HTML
                //   const { textContent } = doc.body;

              //   return textContent;
              // }
              // const extractedText = extractTextFromHTML(comment?.description);
              (
                <UserActivity
                  customClasses={`${isEditing && commentEdit?.currentIndex === i
                    ? 'current'
                    : ''
                    }
              ${comment?.isExternal && classes.externalComment} `}
                  meta={{
                    description: '',
                    createdBy: comment?.createdByName,
                    createdAt: comment?.createdAt,
                    isExternal: true,
                  }}
                  details={parseHTML(comment?.description, {
                    replace: modifyImgAttributes,
                  })}
                  filesPath={comment?.attachmentPath}
                  actions={[
                    // <Button
                    //   size="small"
                    //   title="Translate"
                    //   type="button"
                    //   onClick={(e) => {
                    //     e.preventDefault();
                    //     e.stopPropagation();
                    //     showTranslation(
                    //       extractedText,
                    //       languageType,
                    //       'caseCommentData',
                    //       comment?.id
                    //     );
                    //   }}
                    //   sx={{ padding: '0' }}>
                    //   Translate
                    // </Button>,
                    comment?.createdBy === user?.id && <Button
                      size="small"
                      title="Edit comment"
                      type="button"
                      onClick={() => {
                        editComment(
                          caseData?.id,
                          comment?.id,
                          comment?.description,
                          i,
                          comment?.attachmentPath
                        );
                      }}
                      sx={{ padding: '0' }}>
                      Edit
                    </Button>,
                    comment?.createdBy === user?.id && <Button
                      size="small"
                      title="Delete comment"
                      type="button"
                      onClick={() => {
                        setOpenDialog(true);
                        setCommentDeleteId(comment?.id);
                      }}>
                      Delete
                    </Button>,
                    <Dialog
                      open={openDialog}
                      onClose={handleCloseDialog}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description">
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Are you sure, you want to delete a comment?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseDialog} color="primary">
                          Cancel
                        </Button>
                        <Button
                          onClick={() => deleteComment(caseData?.id)}
                          color="primary"
                          autoFocus>
                          Yes
                        </Button>
                      </DialogActions>
                    </Dialog>,
                  ]}
                />
              )
              )}
          </List>
        </>
      )}
    </Box>
  );
}; // ----> Comments()

export const TimelineSection = ({
  user,
  data,
  caseData,
  toggleIsLoad,
  languageType,
  showTranslation,
  handleDocumentClick,
  handleImageClick,
}: TimelineSectionTypes) => {
  const classes = useStyles();
  return (
    <>
      <Comments
        caseData={caseData}
        toggleIsLoad={toggleIsLoad}
        user={user}
        languageType={languageType}
        showTranslation={showTranslation}
        handleDocumentClick={handleDocumentClick}
        handleImageClick={handleImageClick}
      />
      {user?.permissions?.includes('case-timeline-activity-get-all') && (
        <List className={classes.timelineRoot}>
          {data?.length ? (
            <>
              <span
                style={{
                  fontSize: '16px',
                  fontWeight: 600,
                }}>
                Logs
              </span>
              {caseData?.logs?.map((item: TimelineTypes) => (
                <UserActivity
                  key={item?.id}
                  // image={{ src: '/static/icons/avatar.png', alt: item?.createdBy?.name }}
                  meta={{
                    description: item?.description,
                    createdBy: item?.createdBy.name,
                    createdAt: item?.createdAt,
                    change:
                      item?.change === null ? '' : parseHTML(item?.change),
                  }}
                  filesPath={item?.attachmentPath}
                  actions={[
                    <Button
                      size="small"
                      title="Translate"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (item?.change !== null) {
                          showTranslation(
                            item?.change,
                            languageType,
                            'caseLogData',
                            item?.id
                          );
                        }
                      }}
                      sx={{ padding: '0' }}>
                      Translate
                    </Button>,
                  ]}
                  details={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                      }}>
                      {item?.changeFrom && (
                        <span style={{ fontSize: '12px' }}>
                          {item?.changeFrom === null
                            ? 'none'
                            : parseHTML(item?.changeFrom)}
                        </span>
                      )}
                      {item?.changeTo && (
                        <>
                          <span
                            style={{
                              flex: '0 0 40px',
                              textAlign: 'center',
                              margin: '-2px 0 0',
                            }}>
                            <ArrowRightAltIcon />
                          </span>
                          <span style={{ fontSize: '12px' }}>
                            {item?.changeTo === null
                              ? 'none'
                              : parseHTML(item?.changeTo)}
                          </span>
                        </>
                      )}
                    </div>
                  }
                />
              ))}
            </>
          ) : (
            <>
              <ListItem style={{ display: 'flex', justifyContent: 'center' }}>
                <strong>No Data Found</strong>
              </ListItem>
            </>
          )}
        </List>
      )}
    </>
  );
};
// ----> TimelineSection()

export const JiraSync = () => null;

function EditCases() {
  setTitle('CRM | Cases Edit');
  const { user } = useAuth();
  const { caseID } = useParams();

  const classes = useStyles();
  interface Status {
    id: string;
    name: string;
    rank: number;
  }

  interface Sponsor {
    id: string;
    name: string;
  }

  interface Stakeholder {
    id: string;
    name: string;
  }

  interface Comment {
    id: string;
    description: string;
    isExternal: boolean;
    createdBy: string;
    createdByName: string;
    updatedByName: string | null;
    updatedBy: string | null;
    createdAt: string;
    updatedAt: string;
    attachmentPath: string[];
  }

  interface Assignee {
    id: string | null;
    name: string | null;
  }

  interface Organization {
    id: string;
    name: string;
  }

  interface Project {
    id: string;
    name: string;
  }

  interface IssueType {
    id: string;
    name: string;
  }

  interface Priority {
    id: string;
    name: string;
    rank: number;
  }

  interface EstimatedTime {
    firstResponseTime: string;
    resolveTime: string;
  }

  interface ActualTime {
    firstResponseTime: string;
    resolveTime: string;
  }

  interface SLA {
    estimatedTime: EstimatedTime;
    actualTime: ActualTime;
    isFirstResponseTimeViolated: boolean;
    isResolveTimeViolated: boolean;
  }

  interface Log {
    id: string;
    description: string;
    createdBy: {
      id: string;
      name: string;
    };
    createdAt: string;
    changeFrom: string | null;
    changeTo: string | null;
    change: string | null;
    isExternal: boolean;
  }

  interface Report extends Log { }

  interface Severity {
    id: string;
    name: string;
    rank: number;
  }

  interface BugQuestion {
    questionKey: string;
    description: string;
  }

  interface ThirdPartySync {
    isSynced: boolean;
    syncedCaseKey: string | null;
    syncedCaseLink: string | null;
  }

  interface CreatedBy {
    id: string;
    name: string;
  }

  interface UpdatedBy {
    id: string;
    name: string;
  }

  interface Escalation {
    id: number;
    createdAt: string;
    escalatedBy: {
      id: string;
      name: string;
      role: string;
    };
  }

  interface CaseData {
    labels: string[];
    docUrls: any;
    id: string;
    caseKey: string;
    description: string;
    summary: string;
    status: Status;
    startDate: string;
    dueDate: string;
    projectSponsor: Sponsor[];
    stakeHolder: Stakeholder[];
    attachmentId: string[];
    comment: Comment[];
    assignee: Assignee;
    escalationManager: {
      id: string;
      name: string;
    };
    organization: Organization;
    project: Project;
    issueType: IssueType;
    caseType: {
      id: string;
      name: string;
    };
    priority: Priority;
    sla: SLA;
    logs: Log[];
    reports: Report[];
    severity: Severity;
    bugQuestions: BugQuestion[];
    thirdPartySync: ThirdPartySync;
    isEscalated: boolean;
    createdBy: CreatedBy;
    updatedBy: UpdatedBy;
    createdAt: string;
    updatedAt: string;
    escalations: Escalation[];
    sprint?: any;
    expense?: any;
    releaseVersion?: any;
    timeEstimate?: any;
    loggedTime?: any;
    isApproved?: any;
    approvedBy?: any;
    bugOccur?: any;
    bugExpect?: any;
    billingAccountType?: any;
  }
  interface LogEntry {
    id: string;
    description: string;
    createdBy: {
      id: string;
      name: string;
    };
    createdAt: string;
    changeFrom?: string | null;
    changeTo?: string | null;
    change: string | null;
    isExternal?: boolean;
    attachmentPath?: string;
  }
  const [caseData, setCaseData] = useState<CaseData>(null);
  const [labelsData, setLabelsData] = useState<string[]>([]);
  const [dueDate, setDueDate] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const theme = useTheme();
  const { settings } = useSettings();
  const [caseType, setCaseType] = useState<any>({});
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const toggleIsLoad = () => setIsLoad(!isLoad);
  const [allLogs, setAllLogs] = useState<LogEntry[]>([]);
  const [description, setDescription] = useState<string>('');
  const [tabValue, setTabValue] = useState<number>(1);
  const [languageType, setLanguageType] = useState<string>('en');
  const [timeEstimate, setTimeEstimate] = useState('');
  const [loggedTime, setLoggedTime] = useState([]);
  const [totalTime, setTotalTime] = useState(0);
  const [totalLoggedTime, setTotalLoggedTime] = useState<any>('0h');
  const [remainingTime, setRemainingTime] = useState<any>('0h');
  const [isImagePopupOpen, setIsImagePopupOpen] = useState<boolean>(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState<string>('');
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState<string>('');
  const [isDocumentViewerOpen, setIsDocumentViewerOpen] =
    useState<boolean>(false);
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false);

  const [caseObjectId, setCaseObjectId] = useState<string>('');

  const handleOpenApproveModal = () => {
    setIsApproveModalOpen(true);
  };

  const handleOpenDeclineModal = () => {
    setIsDeclineModalOpen(true);
  };

  const handleCloseApproveModal = () => {
    setIsApproveModalOpen(false);
  };

  const handleCloseDeclineModal = () => {
    setIsDeclineModalOpen(false);
  };

  const convertSecondsToTimeString = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    let result = '';
    if (hours > 0) {
      result += `${hours}h `;
    }
    if (minutes > 0) {
      result += `${minutes}m`;
    }

    return result.trim();
  };

  const getCaseById = async () => {
    try {
      const response = await casesApi?.getCaseById(caseID);
      const res = response?.data;
      setCaseData(res);
      setCaseType(res?.caseType);
      setLabelsData(res?.labels);
      setAllLogs(res?.logs || []);
      setLoggedTime(res?.loggedTime || []);
      setDescription(res?.description || '');
      setDueDate(res?.dueDate);
      setStartDate(res?.startDate);
      const timeString = convertSecondsToTimeString(res?.timeEstimate);
      setTimeEstimate(timeString);
      setTotalTime(res?.timeEstimate);
      setCaseObjectId(res?.id);
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
        setCaseData(null);
        setLabelsData([]);
        setAllLogs([]);
      }
    }
  };

  const handleApprove = async () => {
    // Handle the approval logic here
    try {
      await casesApi.approveCase(caseObjectId);
      handleCloseApproveModal();
      await getCaseById();
      toast.success('Case Approved!');
    } catch (error) {
      logger(error);
      handleCloseApproveModal();
      toast.error('Failed to approve the case');
    }
  };

  const handleDecline = async (reason: string) => {
    // Handle the decline logic here, including using the reason
    try {
      const payload = {
        caseId: caseObjectId,
        description: reason,
        isExternal: true,
        attachmentPath: [],
      };
      await casesApi.createComment(payload);
      await casesApi.declineCase(caseObjectId);
      handleCloseDeclineModal();
      await getCaseById();
      toast.success('Case Declined!');
    } catch (error) {
      logger(error);
      handleCloseDeclineModal();
      toast.error('Failed to decline the case');
    }
  };

  const handleDocumentClick = (documentUrl) => {
    setSelectedDocumentUrl(documentUrl);
    setIsDocumentViewerOpen(true);
  };

  const handleCloseDocumentViewer = () => {
    setIsDocumentViewerOpen(false);
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setIsImagePopupOpen(true);
  };

  const handleCloseImagePopup = () => {
    setIsImagePopupOpen(false);
  };

  useEffect(() => {
    // Sum all logged hours in seconds
    const totalLoggedSeconds = loggedTime.reduce(
      (acc, item) => acc + item?.loggedHoursInSeconds,
      0
    );

    setTotalLoggedTime(convertSecondsToTimeString(totalLoggedSeconds));

    setRemainingTime(
      convertSecondsToTimeString(totalTime - totalLoggedSeconds)
    );
  }, [totalTime]);

  const handleTabValue = (e: any, newValue: number) => {
    setTabValue(newValue);
  };

  // const priorityColors = {
  //   highest: '#FF0000', // Red
  //   blocker: '#FF4500', // Orange Red
  //   high: '#F16063', // High (as specified)
  //   medium: '#FFA500', // Orange
  //   minor: '#FFD700', // Gold
  //   low: '#9ACD32', // Yellow Green
  //   lowest: '#00FF00', // Lime
  //   backLog: '#808080', // Gray
  // };
  // const getColor = (priorityName: any) =>
  //   priorityColors[priorityName.toLowerCase()] || '#000000';

  const BreadCrumbsData = {
    title: '',
    links: [
      {
        name: 'cases',
        to: '/cases',
      },
      {
        name: `${caseData?.caseKey}`,
        to: `/cases/${caseID}`,
        color: `${caseData?.issueType?.name === 'Bug' ? 'red' : '#4baee8'}`,
        copyDetails: {
          id: caseData?.caseKey,
          title: caseData?.summary,
        },
        allowCopy: true,
      },
      {
        name: `${caseData?.caseType?.name}`,
        to: `/cases/${caseID}`,
      },
    ],
  };

  const showTranslation = async (
    textData: string,
    lang?: string,
    field?: string,
    id?: string
  ) => {
    const payload = {
      text: textData,
      target_lang: lang,
    };

    try {
      const res = await translationApi?.translateText(payload);
      const detectedLanguage =
        res?.data?.data?.translations[0].detectedSourceLanguage;
      const translatedText = res?.data?.data?.translations[0].translatedText;
      const updatedComments = caseData.comment.map((comment) => {
        if (comment.id === id) {
          return { ...comment, description: translatedText };
        }
        return comment;
      });
      const updatedLogs = caseData.logs.map((log) => {
        if (log.id === id) {
          return { ...log, change: translatedText };
        }
        return log;
      });
      // Define a conditional object to specify how to update the state based on the field
      const fieldActions = {
        caseSummary: () => {
          setCaseData({
            ...caseData,
            summary: translatedText,
          });
        },
        caseCommentData: () => {
          setCaseData({
            ...caseData,
            comment: updatedComments,
          });
        },
        caseLogData: () => {
          setCaseData({
            ...caseData,
            logs: updatedLogs,
          });
        },
        caseDescription: () => {
          setDescription(translatedText);
        },
        // Add more fields and actions as needed
      };

      // Call the appropriate action based on the field
      if (field && fieldActions[field]) {
        fieldActions[field]();
      }

      setLanguageType(detectedLanguage);
    } catch (error) {
      if (error?.response?.status !== 401) {
        setCaseData(null);
        toast.error(error?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    getCaseById();
  }, [isLoad]);

  return (
    <>
      {
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
          }}
          className={classes.box}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            padding="30px 15px"
            marginBottom="10px"
            sx={{ backgroundColor: 'white' }}>
            <Typography fontSize="22px" fontWeight="700">
              {caseData?.summary}
            </Typography>
            <Box>
              {caseData?.caseKey.startsWith('AFR') && (
                <>
                  {caseData?.status?.name !== 'Declined' && (
                    <CustomButton
                      value={caseData?.isApproved ? 'Approved' : 'Approve'}
                      customClass={classes.approveCaseButton}
                      color="secondary"
                      onClick={handleOpenApproveModal}
                      disabled={caseData?.isApproved}
                    />
                  )}
                  {!caseData?.isApproved &&
                    <CustomButton
                      value={
                        caseData?.status?.name === 'Declined' ? 'Declined' : 'Decline'
                      }
                      customClass={`${classes.declineCaseButton} ${classes.declineButtonRed}`}
                      onClick={handleOpenDeclineModal}
                      disabled={caseData?.status?.name === 'Declined'}
                    />}
                </>
              )}
            </Box>
            {/* Use the ApproveDialog Component */}
            <ApproveDialog
              open={isApproveModalOpen}
              onClose={handleCloseApproveModal}
              onApprove={handleApprove}
            />

            {/* Use the DeclineDialog Component */}
            <DeclineDialog
              open={isDeclineModalOpen}
              onClose={handleCloseDeclineModal}
              onDecline={handleDecline}
            />
          </Box>
          <Container maxWidth={settings.compact ? 'xl' : false}>
            <form>
              <Grid
                container
                gap={4}
                sx={{ flexWrap: 'nowrap', marginTop: '8px' }}>
                {/** Left Side Bar */}
                <Grid
                  item
                  xs={8}
                  sx={{
                    flexDirection: 'column',
                    gap: 4,
                  }}>
                  <Box className="boxSub">
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid
                          container
                          justifyContent="space-between"
                          // spacing={3}
                          mb={1}>
                          <Grid item md={12}>
                            <SimpleBreadCrumb data={BreadCrumbsData} />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid xs={12}>
                        <Box
                          sx={{
                            fontSize: '22px',
                            fontWeight: '700',
                            margin: 0,
                            padding: 0,
                          }}>
                          {caseData?.summary}
                        </Box>
                      </Grid>
                      <Grid item xs={12} sx={{ marginTop: 2 }}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            cursor: 'pointer',
                          }}>
                          <label htmlFor="description">
                            {`${`Description`}`}
                          </label>
                          {/* <div className={classes.transalationBtn}>
                            <Tooltip title="Translate">
                              <TranslateIcon
                                sx={{ height: 18 }}
                                onClick={() => {
                                  showTranslation(
                                    description,
                                    languageType,
                                    'caseDescription'
                                  );
                                }}
                              />
                            </Tooltip>
                          </div> */}
                        </div>
                        <div data-color-mode="light" className="editorMarkup">
                          <Box
                            style={{
                              cursor: 'pointer',
                              marginTop: 8,
                              marginBottom: 8,
                            }}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: description,
                              }}
                            />
                          </Box>

                          <div>
                            {caseData?.caseType?.name === 'Application Bug' && (
                              <Box
                                style={{ cursor: 'pointer' }}
                                display="flex"
                                flexDirection="column"
                                gap="10px">
                                <label
                                  style={{ cursor: 'pointer' }}
                                  htmlFor="label">
                                  {caseData?.bugQuestions[0]?.questionKey}
                                </label>
                                <>{caseData?.bugOccur}</>
                                <label
                                  style={{ cursor: 'pointer' }}
                                  htmlFor="label">
                                  {caseData?.bugQuestions[1]?.questionKey}
                                </label>
                                <>{caseData?.bugExpect}</>
                              </Box>
                            )}
                          </div>

                          {caseData?.attachmentId?.length > 0 && (
                            <div className="attachmentsDiv">
                              <Typography
                                gutterBottom
                                variant="h4"
                                component="div"
                                sx={{
                                  marginBottom: '16px',
                                  fontWeight: 'bolder',
                                }}>
                                Attachments
                              </Typography>

                              {caseData?.attachmentId?.length > 0 && (
                                <div className="editCommentFileParent">
                                  {caseData?.attachmentId?.map((item) => {
                                    const splittedUrl = item?.split('?');
                                    splittedUrl[1] =
                                      'sp=racwdl&st=2024-02-27T13:21:58Z&se=2030-07-29T21:21:58Z&spr=https&sv=2022-11-02&sr=c&sig=YXOHT7%2Bx8VW3RDdgzehtPZLWJ6zHJz%2Bp3QtnduafL4o%3D';
                                    item = splittedUrl?.join('?');
                                    const getFileType = (items) => {
                                      if (items.includes('.pdf')) {
                                        return 'PDF';
                                      }
                                      if (items.includes('.doc')) {
                                        return 'DOC';
                                      }
                                      if (items.includes('.docx')) {
                                        return 'DOCX';
                                      }
                                      if (items.includes('.txt')) {
                                        return 'TXT';
                                      }
                                      if (items.includes('.xls')) {
                                        return 'XLS';
                                      }
                                      if (items.includes('.xlsx')) {
                                        return 'XLSX';
                                      }
                                      if (items.includes('.csv')) {
                                        return 'CSV';
                                      }
                                      if (items.includes('.tsv')) {
                                        return 'TSV';
                                      }
                                      return null;
                                    };

                                    const fileType = getFileType(item);
                                    if (
                                      item?.includes('.pdf') ||
                                      item?.includes('.doc') ||
                                      item?.includes('.docx') ||
                                      item?.includes('.xls') ||
                                      item?.includes('.xlsx') ||
                                      item?.includes('.csv') ||
                                      item?.includes('.tsv') ||
                                      item?.includes('.txt')
                                    ) {
                                      return (
                                        <>
                                          <a
                                            role="presentation"
                                            onClick={() =>
                                              handleDocumentClick(item)
                                            }>
                                            {' '}
                                            <span>{fileType}</span>
                                            <InsertDriveFileIcon
                                              sx={{ fontSize: '60px' }}
                                            />
                                          </a>
                                        </>
                                      );
                                    }
                                    const imageFormats = [
                                      '.jpg',
                                      '.jpeg',
                                      '.png',
                                      '.gif',
                                      '.bmp',
                                      '.tiff',
                                      '.webp',
                                      '.svg',
                                      '.ico',
                                    ];
                                    if (
                                      imageFormats.some((extension) =>
                                        item?.includes(extension)
                                      )
                                    ) {
                                      return (
                                        <>
                                          <a>
                                            <img
                                              role="presentation"
                                              src={item}
                                              alt="img"
                                              onClick={() =>
                                                handleImageClick(item)
                                              }
                                            />
                                          </a>
                                        </>
                                      );
                                    }
                                    return null;
                                  })}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        <Card
                          style={{
                            marginTop: '32px',
                            padding: '5px 10px',
                            boxShadow: '0px 0px 1px 1px #0C1A4B3D',
                            borderRadius: '16px',
                          }}>
                          {' '}
                          <Tabs
                            value={tabValue}
                            onChange={handleTabValue}
                            style={{ borderBottom: '1px solid #D9D9D9' }}
                            TabIndicatorProps={{
                              style: {
                                backgroundColor: theme.palette.primary.main,
                              },
                            }}>
                            <Tab value={1} label="TimeLine" />
                          </Tabs>
                          <div>
                            {tabValue === 1 && (
                              <TimelineSection
                                data={allLogs}
                                caseData={caseData}
                                toggleIsLoad={toggleIsLoad}
                                user={user}
                                languageType={languageType}
                                showTranslation={showTranslation}
                                handleDocumentClick={handleDocumentClick}
                                handleImageClick={handleImageClick}
                              />
                            )}
                          </div>
                        </Card>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

                <Grid item xs={4}>
                  <Card className="rightSide">
                    <CardContent>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}>
                        <div className="rightAccordion">
                          <Typography gutterBottom variant="h4" component="div">
                            Details
                          </Typography>
                          <Divider />
                          <div
                            style={{
                              display: 'grid',
                              gridTemplateColumns: '1fr 1fr ',
                              rowGap: '15px',
                            }}>
                            <Typography fontSize="14px" fontWeight="700">
                              Status
                            </Typography>

                            <Typography
                              fontSize="14px"
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                              }}>
                              {caseData?.status?.name}
                            </Typography>

                            <Typography fontSize="14px" fontWeight="700">
                              Case Type
                            </Typography>

                            <Typography
                              fontSize="14px"
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                              }}>
                              {caseType?.name || 'N/A'}
                            </Typography>

                            <Typography fontSize="14px" fontWeight="700">
                              Priority
                            </Typography>

                            <Typography
                              fontSize="14px"
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                              }}>
                              {caseData?.priority?.name}
                            </Typography>

                            {caseData?.caseType?.name === 'Application Bug' && (
                              <Typography fontSize="14px" fontWeight="700">
                                Severity
                              </Typography>
                            )}

                            {caseData?.caseType?.name === 'Application Bug' && (
                              <Typography
                                fontSize="14px"
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'flex-start',
                                }}>
                                {caseData?.severity?.name}
                              </Typography>
                            )}

                            <Typography fontSize="14px" fontWeight="700">
                              Assignee
                            </Typography>

                            <Typography
                              fontSize="14px"
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                              }}>
                              {caseData?.assignee?.name?.length
                                ? caseData?.assignee?.name
                                : 'N/A'}
                            </Typography>

                            <Typography fontSize="14px" fontWeight="700">
                              Project
                            </Typography>

                            <Typography
                              fontSize="14px"
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                              }}>
                              {caseData?.project?.name?.length
                                ? caseData?.project?.name
                                : 'N/A'}
                            </Typography>

                            <Typography fontSize="14px" fontWeight="700">
                              Sprints
                            </Typography>

                            <Typography
                              fontSize="14px"
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                              }}>
                              {caseData?.sprint?.name?.length > 0
                                ? caseData?.sprint?.name
                                : 'N/A'}
                            </Typography>

                            <Typography fontSize="14px" fontWeight="700">
                              Fix Versions
                            </Typography>
                            <Typography
                              fontSize="14px"
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                              }}>
                              {caseData?.releaseVersion?.length > 0
                                ? caseData?.releaseVersion[0]?.name
                                : 'N/A'}
                            </Typography>

                            <Typography fontSize="14px" fontWeight="700">
                              Labels
                            </Typography>
                            <Box display="flex" flexDirection="row" gap="5px">
                              {labelsData?.length > 0 ? (
                                labelsData?.map((item) => (
                                  <Box key={item}>
                                    <Chip
                                      sx={{
                                        cursor: 'pointer',
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: 'black',
                                        textShadow: '2px black',
                                        maxWidth: '100px',
                                      }}
                                      label={item}
                                      size="small"
                                    />
                                  </Box>
                                ))
                              ) : (
                                <Box>N/A</Box>
                              )}
                            </Box>

                            <Typography fontSize="14px" fontWeight="700">
                              Expense
                            </Typography>

                            <Box
                              sx={{
                                display: 'inline-flex',
                              }}
                              maxWidth="150px">
                              <Typography
                                fontSize="14px"
                                color={
                                  caseData?.expense === 'Opex'
                                    ? theme.palette.primary.main
                                    : 'rgba(29, 122, 21, 1)'
                                }
                                fontWeight="700"
                                sx={{
                                  cursor: 'pointer',
                                  backgroundColor:
                                    caseData?.expense === 'Opex'
                                      ? '#FFE6E4'
                                      : 'rgba(7, 83, 0, 0.1)',
                                  padding: '4px 8px',
                                  borderRadius: '4px',
                                }}>
                                {caseData?.expense || 'N/A'}
                              </Typography>
                            </Box>

                            <Typography fontSize="14px" fontWeight="700">
                              Start Date
                            </Typography>

                            <Box
                              sx={{
                                display: 'inline-flex',
                              }}
                              maxWidth="150px">
                              <Typography
                                fontSize="14px"
                                fontWeight="700"
                                sx={{ cursor: 'pointer' }}>
                                <Typography fontSize="14px">
                                  {startDate?.length > 0
                                    ? moment(startDate)?.format('DD MMM, YYYY')
                                    : 'N/A'}
                                </Typography>
                              </Typography>
                            </Box>

                            <Typography fontSize="14px" fontWeight="700">
                              Due Date
                            </Typography>
                            <Box
                              sx={{
                                display: 'inline-flex',
                              }}
                              maxWidth="150px">
                              <Typography
                                fontSize="14px"
                                fontWeight="700"
                                sx={{ cursor: 'pointer' }}>
                                <Typography fontSize="14px">
                                  {dueDate?.length > 0
                                    ? moment(dueDate)?.format('DD MMM, YYYY')
                                    : 'N/A'}
                                </Typography>
                              </Typography>
                            </Box>

                            <Typography fontSize="14px" fontWeight="700">
                              Time Estimate
                            </Typography>

                            <Typography fontSize="14px">
                              {timeEstimate || '0h'}
                            </Typography>

                            <Typography fontSize="14px" fontWeight="700">
                              Logged Time
                            </Typography>

                            <Typography fontSize="14px">
                              {totalLoggedTime || '0h'}
                            </Typography>

                            <Typography fontSize="14px" fontWeight="700">
                              Remaining Time
                            </Typography>

                            <Typography fontSize="14px">
                              {remainingTime || '0h'}
                            </Typography>

                            <Typography fontSize="14px" fontWeight="700">
                              Account
                            </Typography>

                            <Typography fontSize="14px">
                              {caseData?.billingAccountType?.name?.length > 0
                                ? caseData?.billingAccountType?.name
                                : 'N/A'}
                            </Typography>

                            <Typography fontSize="14px" fontWeight="700">
                              Case Owner / Reporter
                            </Typography>
                            <Typography fontSize="14px" fontWeight="700">
                              {caseData?.createdBy?.name?.length > 0
                                ? caseData?.createdBy?.name
                                : 'N/A'}
                            </Typography>
                          </div>
                        </div>

                        <div
                          className="createdAt"
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: '10px',
                          }}>
                          <p
                            title={moment(caseData?.createdAt).format(
                              'DD MMM, YYYY - HH:mm:ss'
                            )}>
                            Created:{' '}
                            <span>
                              {time.absoluteTime(caseData?.createdAt)}
                            </span>
                          </p>
                          <p
                            title={moment(caseData?.updatedAt).format(
                              'DD MMM, YYYY - HH:mm:ss'
                            )}>
                            Updated:{' '}
                            <span>
                              {time.absoluteTime(caseData?.updatedAt)}
                            </span>
                          </p>
                        </div>
                      </div>
                    </CardContent>
                  </Card>

                  <Card className="rightSide" sx={{ marginTop: '1rem' }}>
                    <CardContent>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}>
                        <div className="rightAccordion">
                          <Typography gutterBottom variant="h4" component="div">
                            Cases Attachments
                          </Typography>
                          <Divider />
                        </div>

                        <div
                          style={{
                            marginTop: '10px',
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            rowGap: '15px',
                          }}>
                          <Typography fontSize="14px" fontWeight="700">
                            Google Doc Links
                          </Typography>
                          {caseData?.docUrls?.googleDocLinks?.length === 0 && (
                            <Typography fontSize="14px" fontWeight="400">
                              No Documents Available
                            </Typography>
                          )}
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}>
                            {caseData?.docUrls?.googleDocLinks?.map((item) => (
                              <Typography fontSize="14px" fontWeight="400">
                                <a
                                  rel="noreferrer"
                                  target="_blank"
                                  href={item?.url}>
                                  {item?.label}
                                </a>
                              </Typography>
                            ))}
                          </div>
                        </div>

                        <div
                          style={{
                            marginTop: '10px',
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            rowGap: '15px',
                          }}>
                          <Typography fontSize="14px" fontWeight="700">
                            Figma Links
                          </Typography>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}>
                            {caseData?.docUrls?.figmaLinks?.map((item) => (
                              <Typography fontSize="14px" fontWeight="400">
                                <a
                                  rel="noreferrer"
                                  target="_blank"
                                  href={item?.url}>
                                  {item?.label}
                                </a>
                              </Typography>
                            ))}
                          </div>
                          {caseData?.docUrls?.figmaLinks?.length === 0 && (
                            <Typography fontSize="14px" fontWeight="400">
                              No Documents Available
                            </Typography>
                          )}
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              <SImpleModal
                isOpen={isImagePopupOpen}
                onClose={handleCloseImagePopup}
                imageUrl={selectedImageUrl}
              />

              {isDocumentViewerOpen && (
                <DocumentViewer
                  documentUrl={selectedDocumentUrl}
                  isOpen={isDocumentViewerOpen}
                  onClose={handleCloseDocumentViewer}
                />
              )}
            </form>
          </Container>
        </Box>
      }
    </>
  );
}

export default EditCases;
